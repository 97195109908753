/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* --- Poppins Font Import --- */

@font-face {
  font-family: "Poppins-Black";
	src: url(./Fonts/Poppins-Black.ttf);
}

@font-face {
  font-family: "Poppins-BoldItalic";
	src: url(./Fonts/Poppins-BoldItalic.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
	src: url(./Fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Poppins-BlodItalic";
	src: url(./Fonts/Poppins-BoldItalic.ttf);
}

@font-face {
  font-family: "Poppins-ExtraBold";
	src: url(./Fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
	src: url(./Fonts/Poppins-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: "Poppins-ExtraLight";
	src: url(./Fonts/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
	src: url(./Fonts/Poppins-ExtraLightItalic.ttf);
}

@font-face {
  font-family: "Poppins-Italic";
	src: url(./Fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: "Poppins-Light";
	src: url(./Fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "Poppins-LightItalic";
	src: url(./Fonts/Poppins-LightItalic.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
	src: url(./Fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Poppins-MediumItalic";
	src: url(./Fonts/Poppins-MediumItalic.ttf);
}

@font-face {
  font-family: "Poppins-Regular";
	src: url(./Fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-SemiBold";
	src: url(./Fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
	src: url(./Fonts/Poppins-SemiBoldItalic.ttf);
}

@font-face {
  font-family: "Poppins-Thin";
	src: url(./Fonts/Poppins-Thin.ttf);
}

@font-face {
  font-family: "Poppins-ThinItalic";
	src: url(./Fonts/Poppins-ThinItalic.ttf);
}

/* --- Poppins Font Import --- */

/* --- NotoSansKR Font Import --- */

@Font-face {
	font-family: "NotoSansKR-Black";
	src: url(./Fonts/NotoSansKR-Black.otf);
}

@Font-face {
	font-family: "NotoSansKR-Bold";
	src: url(./Fonts/NotoSansKR-Bold.otf);
}

@Font-face {
	font-family: "NotoSansKR-Light";
	src: url(./Fonts/NotoSansKR-Light.otf);
}

@Font-face {
	font-family: "NotoSansKR-Medium";
	src: url(./Fonts/NotoSansKR-Medium.otf);
}

@Font-face {
	font-family: "NotoSansKR-Regular";
	src: url(./Fonts/NotoSansKR-Regular.otf);
}

@Font-face {
	font-family: "NotoSansKR-Thin";
	src: url(./Fonts/NotoSansKR-Thin.otf);
}

/* --- NotoSansKR Font Import --- */
